import request from '@/utils/request'

const property_balance_userApi = {
  property_balance_list: '/corporation/property_balance/',
  property_balance_export: '/corporation/property_balance/export/',
  current_property_balance: '/corporation/corporation_id/property_balance/'
}

/**
 * 列表
 */
export function property_balance_list (parameter) {
  return request({
    url: property_balance_userApi.property_balance_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}

/**
 * 列表
 */
export function current_property_balance (corporation_id) {
  return request({
    url: property_balance_userApi.current_property_balance.replace('corporation_id', corporation_id),
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 导出
 */
export function property_balance_export (parameter) {
  return request({
    url: property_balance_userApi.property_balance_export,
    method: 'get',
    headers: {
      'Content-Type': 'application/octet-stream'
    },
    responseType: 'blob',
    params: parameter
  })
}
